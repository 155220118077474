import React from "react";
import { graphql } from "gatsby";
import Img from "gatsby-image";
import Layout from "../components/layout";
import SEO from "../components/seo";
const NewsItem = ({ data }) => {
  const { title, body, heroImage, tags, publishDate } = data.contentfulNewsItem;
  return (
    <Layout>
      <SEO title={title} />
      <div className="">
        <Img
          className="object-cover w-full h-64"
          fluid={heroImage.fluid}
          alt="Header"
        />
      </div>
      <div className="container px-4 mx-auto mt-6">
        <div className="">
          {tags.map(tag => (
            <span className="border border-purple-600 text-purple-800 uppercase font-bold px-2 py-0 tracking-widest text-xs" key={tag}>
              {tag}
            </span>
          ))}
        </div>

        <div className="flex">

          <div className="sm:w-2/3">
            <h1 className="news-title">{title}</h1>
            <div className="font-serif italic mb-8 mt-8">{publishDate}</div>
            <div className="mt-12 mb-12 leading-loose bg-white px-12 py-12"

              dangerouslySetInnerHTML={{
                __html: body.childMarkdownRemark.html,
              }}
            />

          </div>
          <div className=""></div>
        </div>
       
      </div>
    </Layout>
  );
};
export default NewsItem;
export const pageQuery = graphql`
  query($slug: String!) {
    contentfulNewsItem(slug: { eq: $slug }) {
      title
      slug
      publishDate(formatString: "D MMMM, YYYY")
      tags
      body {
        childMarkdownRemark {
          html
        }
      }
      heroImage {
        fluid(maxWidth: 2000, resizingBehavior: SCALE) {
          ...GatsbyContentfulFluid_tracedSVG
        }
      }
      tags
    }
    file(relativePath: { eq: "banner-home.png" }) {
      childImageSharp {
        fixed(width: 1200) {
          ...GatsbyImageSharpFixed
        }
      }
    }    
  }
`;